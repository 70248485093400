import NoSleep from "@uriopass/nosleep.js";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  enterFullScreen,
  isMobileDevice,
  lockOrientation,
} from "../utils/fullscreenUtils";
import { SpriteAnimation } from "../utils/SpriteAnimation";

declare global {
  interface Window {
    redirectToHome: () => void;
    setMusicVolume: (val: number) => void;
    setSoundVolume: (val: number) => void;
  }
}

// Define the props type
interface UnityWebGLPlayerProps {
  table: {
    slug: string;
    tableName: string;
    orientation: "landscape-primary" | "portrait-primary";
  };
}

const UnityWebGLPlayer: React.FC<UnityWebGLPlayerProps> = ({ table }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const unityInstanceRef = useRef<any>(null);
  const [loading, setLoading] = useState(true);
  const deviceType = localStorage.getItem("deviceType");
  const username = sessionStorage.getItem("username");
  const navigate = useNavigate();
  const noSleep = new NoSleep();

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column" as const,
      width: table.orientation === "portrait-primary" ? "420px" : "100%",
      height:
        table.orientation === "portrait-primary"
          ? "100%"
          : `min(640px, ${deviceType === "desktop" ? "80%" : "100%"})`,
      position: "relative" as const,
      backgroundColor: "#212123",
      margin: "auto",
    },
    canvas: {
      flexGrow: 1,
      display: "block",
      width: "100%",
      height: "100%",
    },
    loader: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#fff",
      fontSize: "1.5em",
      fontFamily: "Arial, sans-serif",
    },
  };

  useEffect(() => {
    // Enable wake lock when component mounts.
    noSleep.enable();

    // Disable wake lock when component unmounts.
    return () => {
      noSleep.disable();
    };
  });

  useEffect(() => {
    const redirectToHome = async() => {
      await quitUnityInstance();
      navigate("/lobby");
      // window.location.reload();
    };

    const setMusicVolume = (val: number) => {
      Cookies.set(`${username}_music`, val.toFixed(2), { expires: 365 });
    };

    const setSoundVolume = (val: number) => {
      Cookies.set(`${username}_sound`, val.toFixed(2), { expires: 365 });
    };

    window.redirectToHome = redirectToHome;
    window.setMusicVolume = setMusicVolume;
    window.setSoundVolume = setSoundVolume;
  }, [navigate, username]);

  // useEffect(() => {
  //   // Add fullscreen exit listener
  //   const handleFullscreenChange = () => {
  //     if (!document.fullscreenElement) {
  //       navigate("/lobby");
  //     }
  //   };
  //   document.addEventListener("fullscreenchange", handleFullscreenChange);

  //   return () => {
  //     document.removeEventListener("fullscreenchange", handleFullscreenChange);
  //   };
  // }, [navigate]);

  const initializeUnityInstance = useCallback(() => {
    const buildUrl = `/UnityBuilds/${table.slug}`;
    const loaderUrl = `${buildUrl}/Build.loader.js`;
    const config = {
      dataUrl: `${buildUrl}/Build.data.unityweb`,
      frameworkUrl: `${buildUrl}/Build.framework.js.unityweb`,
      codeUrl: `${buildUrl}/Build.wasm.unityweb`,
      companyName: "slot-games",
      productName: table.tableName,
      productVersion: "1.0",
    };

    const script = document.createElement("script");
    script.src = loaderUrl;

    script.onload = () => {
      if (typeof createUnityInstance !== "undefined" && canvasRef.current) {
        createUnityInstance(canvasRef.current, config, (progress) => {
          setLoading(progress !== 1);
        })
          .then((unityInstance) => {
            unityInstanceRef.current = unityInstance;
            if (sessionStorage.getItem("token")) {
              unityInstance.SendMessage(
                "GameManager",
                "OnJSSessionVarReceived",
                sessionStorage.getItem("token")
              );
              setTimeout(() => {
                unityInstance.SendMessage(
                  "GameManager",
                  "MusicSettingsFromWeb",
                  parseFloat(Cookies.get(`${username}_music`) || "0.50")
                );
                unityInstance.SendMessage(
                  "GameManager",
                  "SoundSettingsFromWeb",
                  parseFloat(Cookies.get(`${username}_sound`) || "0.50")
                );
              }, 5000);
            }
            console.log("Unity instance initialized successfully.");
          })
          .catch((err) =>
            console.error("Unity instance initialization error:", err)
          );
      } else {
        console.error("createUnityInstance is not defined.");
      }
    };

    document.body.appendChild(script);

    // return () => {
    //   if (unityInstanceRef.current) {
    //     unityInstanceRef.current.Quit().then(() => {
    //       console.log("Unity instance destroyed.");
    //       // window.location.reload();
    //       // console.log("reloaded");
    //     }).catch((e:any)=>{
    //       console.log("failed to quit with exception",e);
    //     })
    //   }
    //   document.body.removeChild(script);
    // };
  }, [table, username]);

  const adjustForDevice = useCallback(() => {
    const container = containerRef.current;
    const isMobile = isMobileDevice();

    if (isMobile && container) {
      console.log("Enabling fullscreen for mobile.");
      enterFullScreen(container);
      lockOrientation(table.orientation);
    } else {
      console.log("Desktop detected. No fullscreen triggered.");
    }
  }, [table]);

  const quitUnityInstance = async () => {
    if (unityInstanceRef.current) {
      try {
        await unityInstanceRef.current.Quit();
        console.log("Successfully quit Unity instance.");
        unityInstanceRef.current = null;
        
      } catch (error) {
        console.error("Failed to quit Unity instance:", error);
      }
    }
  };
  
  useEffect(() => {
    initializeUnityInstance();
    adjustForDevice();

    return () => {
      if (unityInstanceRef.current) {
        unityInstanceRef.current.Quit().then(()=>{
          console.log("successfully quit")
        }).catch((e:any)=>{
          console.log("failed to quit with exception",e);
        })
      }
    };
  }, [initializeUnityInstance, adjustForDevice]);

  return (
    <div id="unity-container" style={styles.container} ref={containerRef}>
      <canvas id="unity-canvas" style={styles.canvas} ref={canvasRef}></canvas>
      {loading && (
        <div style={styles.loader}>
          <SpriteAnimation
            spriteSheetImage={"sprites/fiesta_loader.png"}
            frameWidth={150}
            frameHeight={150}
            totalFrames={49}
            rows={7}
            cols={7}
            fps={21}
          />
        </div>
      )}
    </div>
  );
};
export default UnityWebGLPlayer;
