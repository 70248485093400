import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableDataMap } from "../../components/utils/constants/tablesData";
import GameTab from "../../components/utils/GameTab";
import SidePanel from "../../components/utils/SidePanel";
import TopPanel from "../../components/utils/topPanel/TopPanel";
import { favTablesLoaded } from "../../store/favTablesSlice";
import { Filters } from "../../store/filtersModel";
import { filtersUpdated } from "../../store/filtersSlice";
import { RootState } from "../../store/store";
import { Table } from "../../store/tablesModel";
import { tablesFiltered, tablesLoaded } from "../../store/tablesSlice";

export default function Lobby() {
  const navigate = useNavigate();
  const filters = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const [isPortrait, setIsPortrait] = useState(false);
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const [isValidating, setIsValidating] = useState(true); // Initially true to prevent rendering
  const [token, setToken] = useState("");

  const favTables = useSelector((state: RootState) => state.favTables);
  const filteredTables = useSelector((state: RootState) =>
    tablesFiltered(state, filters, favTables)
  );

  useEffect(() => {
    const validateTokenAndInitialize = async () => {
      const tokenParam = searchParams.get("token");
      const userName = searchParams.get("userName");
      const enterLobby = searchParams.get("lobby");

      if (tokenParam && userName && enterLobby === "true") {
        setIsValidating(true);
        try {
          const response = await axios.get(
            "https://backend.inferixai.link/api/v1/validate-token",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenParam}`,
              },
            }
          );

          if (response && response.data.description === "Token valid") {
            setToken(tokenParam);
            sessionStorage.setItem("token", tokenParam);
            sessionStorage.setItem("username", userName);

            // Navigate to the same route to remove the token from the URL
            navigate(
              `/lobby?filters=${encodeURIComponent(JSON.stringify(filters))}`,
              {
                replace: true,
              }
            );
          } else {
            throw new Error("Invalid token");
          }
        } catch (err) {
          console.error("Token validation failed", err);
          setError("Invalid token");
        } finally {
          setIsValidating(false);
        }
      } else {
        setIsValidating(false);
      }
    };

    validateTokenAndInitialize();
  }, [searchParams, navigate, filters]);

  useEffect(() => {
    const filtersParam = searchParams?.get("filters");
    const lobbyParam = searchParams?.get("lobby");
    const checktoken = sessionStorage.getItem("token");
    if (checktoken  && !lobbyParam) {
      if (!filtersParam) {
        console.log("without");
        try {
          navigate(
            `/lobby?filters=${encodeURIComponent(JSON.stringify(filters))}`
          );
        } catch (e) {
          console.log("filter error", e);
        }
      }
    } else {
      setError("Token not found");
    }
  }, [searchParams, filters, navigate]);

  useEffect(() => {
    const filtersParam = searchParams?.get("filters");
    const lobbyParam = searchParams?.get("lobby");
    const checktoken = sessionStorage.getItem("token");
    if (checktoken && !lobbyParam) {
      if (filtersParam ) {
        console.log("with");
        try {
          const _filters: Filters = JSON.parse(filtersParam);
          dispatch(filtersUpdated(_filters));
        } catch (error) {
          console.error("Failed to parse filters from URL", error);
        }
      }
    } else {
      setError("Token not found");
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (!token && !sessionStorage.getItem("token")) return;

    const getTables = async () => {
      console.log("called get tables lobby");
      setError("");

      try {
        const authToken = sessionStorage.getItem("token") || token;
        const response = await axios.get(
          "https://backend.inferixai.link/api/gettables",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data && response.data.status === "RS_OK") {
          const fetchedTables: Table[] = response?.data.tables.map(
            (table: any) => ({
              tableId: table.tableId,
              tableName: table.tableName,
              category: table.category,
              slug: table.slug,
              orientation: table.orientation as
                | "landscape-primary"
                | "portrait-primary",
            })
          );

          const filteredTables = fetchedTables?.filter(
            (table) => table.slug in TableDataMap
          );

          dispatch(tablesLoaded(filteredTables));
          dispatch(favTablesLoaded(response.data.favTables));
        } else {
          throw new Error(response.data.message);
        }
      } catch (err: any) {
        console.error(err);
        setError(err.response?.data?.error || "Failed to load tables");
      }
    };

    getTables();
  }, [token, dispatch]);

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    checkOrientation();
    window.addEventListener("resize", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  if (isValidating) {
    <div className="w-full p-2  text-red-600 text-3xl font-medium text-center">
      Validating token...
    </div>;
  }

  if (error) {
    return (
      <div className="w-full p-2  text-red-600 text-3xl font-medium text-center">
        {error}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <TopPanel />
      <div className="flex flex-grow min-h-0">
        <div
          className={`flex-shrink-0 ${
            localStorage.getItem("deviceType") === "desktop"
              ? "w-[148px]"
              : "w-[88px]"
          }`}
        >
          <SidePanel />
        </div>
        <div className="flex-grow overflow-y-auto pl-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 py-4 pr-4">
            {filteredTables?.map((_g: Table) => (
              <div
                key={_g.tableId}
                className="flex justify-center items-center"
              >
                <GameTab
                  key={`${_g.tableId}_tab`}
                  table={_g}
                  favTables={favTables}
                  onGameClick={(gameId: string) =>
                    navigate(`/slot-games/${gameId}`)
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
