import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Filters } from "../../store/filtersModel";
import { tablesFilterUpdated } from "../../store/filtersSlice";
import { RootState } from "../../store/store";
import { SpriteAnimation } from "./SpriteAnimation";

type SidePanelItem = {
  id: string;
  icon: any;
  name: string;
  slug: Filters["tables"];
};

export default function SidePanel() {
  const deviceType = localStorage.getItem("deviceType");
  const filters = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iconDimensions, setIconDimensions] = useState({
    width: 52,
    height: 52,
  });
  const [bgDimensions, setBgDimensions] = useState({
    width: 148,
    height: 468,
  });
  const items: SidePanelItem[] = [
    {
      id: "all",
      icon: "all.png",
      name: "All",
      slug: "all",
    },
    {
      id: "favs",
      icon: "favs.png",
      name: "Favourite",
      slug: "favs",
    },
    {
      id: "slots",
      icon: "slots.png",
      name: "Slots",
      slug: "slots",
    },
  ];

  const handleFilterChange = (_tablesFilter: Filters["tables"]) => {
    try {
      if (_tablesFilter !== filters.tables) {
        dispatch(tablesFilterUpdated(_tablesFilter));
        navigate(
          `/lobby?filters=${encodeURIComponent(
            JSON.stringify({ tables: _tablesFilter })
          )}`
        );
      }
    } catch (e) {
      console.log("error filtering", e);
    }
  };

  useEffect(() => {
    if (deviceType === "desktop") {
      setIconDimensions({ width: 85, height: 85 });
      setBgDimensions({ width: 148, height: 468 });
    } else {
      setIconDimensions({ width: 52, height: 52 });
      setBgDimensions({ width: 88, height: 288 });
    }
  }, [deviceType]);

  return (
     <div className={`h-full ${deviceType === "desktop" ? "w-[148px]" : "w-full"}`}>
      <SpriteAnimation
        spriteSheetImage={"sprites/side_panel_bg.png"}
        frameWidth={bgDimensions.width}
        frameHeight={bgDimensions.height}
        classes={deviceType === "desktop" ? "my-auto ml-2" : "mt-0 ml-1"}
      >
        <div
          className={`flex flex-col h-full justify-evenly m-auto overflow-hidden ${
            deviceType === "desktop" ? "pt-20 pb-8" : "pt-12 pb-4"
          }`}
        >
          {items.map((item, idx) => (
            <div className="flex flex-col items-center" key={item.id + "_ctn"}>
              <SpriteAnimation
                key={item.id + "_icon"}
                spriteSheetImage={`sprites/${item.icon}`}
                frameWidth={iconDimensions.width}
                frameHeight={iconDimensions.height}
                onClick={() => handleFilterChange(item.slug)}
                classes={idx === 0 ? "mt-3" : ""}
              />
              <div
                key={item.id + "_name"}
                className={`flex w-fit mx-auto ${
                  deviceType === "desktop"
                    ? "text-sm font-medium -mt-[14px]"
                    : "text-[10px] font-normal -mt-2"
                } ${
                  filters.tables === item.slug
                    ? "scale-[1.08] text-[#d4a354] font-semibold"
                    : "text-white"
                }`}
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </SpriteAnimation>
    </div>
  );
}

