import { useEffect, useState } from "react";

interface SpriteAnimationProps {
  spriteSheetImage: string;
  frameWidth: number;
  frameHeight: number;
  fps?: number;
  totalFrames?: number;
  rows?: number;
  cols?: number;
  delay?: number;
  onClick?: () => void;
  classes?: string;
}

export function SpriteAnimation({
  spriteSheetImage,
  frameWidth,
  frameHeight,
  fps = 16,
  totalFrames = 25,
  rows = 5,
  cols = 5,
  delay = 0,
  onClick,
  classes,
  children,
}: SpriteAnimationProps & { children?: React.ReactNode }) {
  const [currentFrame, setCurrentFrame] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const TOTAL_FRAMES = totalFrames;
  const ROWS = rows;
  const COLS = cols;

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isPlaying) {
      intervalId = setInterval(() => {
        setCurrentFrame((prev) => {
          if (prev + 1 === TOTAL_FRAMES) {
            setIsPlaying(false);
            return prev;
          }
          return (prev + 1) % TOTAL_FRAMES;
        });
      }, 1000 / fps);
    } else {
      intervalId = setTimeout(() => {
        setCurrentFrame(0); // Reset to the first frame
        setIsPlaying(true); // Restart animation
      }, delay);
    }

    return () => clearInterval(intervalId);
  }, [fps, TOTAL_FRAMES, isPlaying, delay]);
  const row = Math.floor(currentFrame / COLS);
  const col = currentFrame % COLS;

  const spriteSheet = require(`../../assets/${spriteSheetImage}`);

  return (
    <div
      className={`relative ${onClick ? "cursor-pointer" : ""} ${classes}`}
      onClick={() => {
        if (onClick) onClick();
      }}
      style={{
        width: frameWidth,
        height: frameHeight,
        backgroundImage: `url(${spriteSheet})`,
        backgroundPosition: `-${col * frameWidth}px -${row * frameHeight}px`,
        backgroundSize: `${frameWidth * COLS}px ${frameHeight * ROWS}px`,
        imageRendering: "pixelated",
      }}
    >
      {children}
    </div>
  );
}
