import { useEffect, useRef, useState } from "react";
import AudioSettings from "../AudioSettings";
import PopoverMenu from "../PopoverMenu";
import { SpriteAnimation } from "../SpriteAnimation";
import Modal from "../modal/Modal";
import "./TopPanel.css";

export default function TopPanel() {
  const deviceType = localStorage.getItem("deviceType");
  const [balance, setBalance] = useState<number>();
  const [isAudioSettingsOpen, setIsAudioSettingsOpen] =
    useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const wsRef = useRef<WebSocket | null>(null);
  const [frameDimensions, setFrameDimensions] = useState({
    width: 52,
    height: 52,
  });
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found in session storage.");
      return;
    }

    const initializeWebSocket = () => {
      console.log("initializing web socket", wsRef.current)
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        console.log("WebSocket already open. Skipping initialization.");
        return;
      }

      try {
        const ws = new WebSocket(
          `wss://backend.inferixai.link/user/auth?authorization=Bearer ${token}`
        );
        wsRef.current = ws; // Store WebSocket in ref

        ws.onopen = () => {
          console.log("WebSocket connection opened.");

          // Request balance immediately upon connection
          ws.send(JSON.stringify({ operation: "getbalance" }));

          // Request balance every 10 seconds
          const intervalId = setInterval(() => {
            if (ws.readyState === WebSocket.OPEN) {
              ws.send(JSON.stringify({ operation: "getbalance" }));
            }
          }, 10000);

          // Safely handle messages
          ws.onmessage = (event) => {
            try {
              const data = JSON.parse(event.data);
              const balanceData = data.data;
              if (balanceData && balanceData.balance !== undefined) {
                setBalance(balanceData.balance);
              }
            } catch (err) {
              console.error("Error processing WebSocket message:", err);
            }
          };

          ws.onclose = (event) => {
            console.log("WebSocket connection closed:", event);
            clearInterval(intervalId);
            wsRef.current = null; // Reset wsRef to null
          };

          ws.onerror = (error) => {
            console.error("WebSocket error:", error);
            clearInterval(intervalId);
            window.location.reload(); // Reload on error
          };

          // Cleanup function
          return () => {
            console.log("Cleanup called.");
            clearInterval(intervalId);
            if (wsRef.current) {
              wsRef.current.close();
              wsRef.current = null;
            }
          };
        };
      } catch (err) {
        console.error("Failed to initialize WebSocket:", err);
        window.location.reload(); // Reload on failure
      }
    };

    initializeWebSocket();

    // Cleanup function to close WebSocket on component unmount
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
        console.log("WebSocket connection closed during cleanup.");
      }
    };
  }, []);

  
  const handleClickOutside = (event: MouseEvent): void => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the popover
    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopoverOpen]);

  useEffect(() => {
    const pfc = parentRef.current;
    const updateFrameSize = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.offsetWidth;
        // Adjust frame size proportionally based on parent width
        let mFactor = 0.0575 * 0.01 * parentWidth;
        if (deviceType !== "desktop" && parentWidth > 500) {
          mFactor = 0.08 * 0.01 * parentWidth;
        }
        const newWidth = deviceType === "desktop" ? 85 * mFactor : 52;
        const newHeight = newWidth; // Maintain aspect ratio
        setFrameDimensions({
          width: newWidth,
          height: newHeight,
        });
      }
    };

    // Use ResizeObserver to dynamically track the size of the parent element
    const observer = new ResizeObserver(updateFrameSize);

    if (pfc) {
      observer.observe(pfc);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (pfc) {
        observer.unobserve(pfc);
      }
    };
  }, [parentRef, deviceType]);

  return (
    <div
    ref={parentRef}
      className={`flex flex-col bg-[url(https://s3.eu-west-2.amazonaws.com/static.inferixai.link/top_panel.png)] bg-no-repeat bg-top bg-cover p-0 pb-2 pt-[6px] z-[100] ${
        deviceType === "desktop" ? "h-[125px]" : "h-[85px]"
      }`}
  >
      <div className="flex w-full h-full items-center justify-between ">
        <div className="flex flex-col sm:flex-row justify-start ml-2 items-start sm:items-center -mt-4">
        <SpriteAnimation
              spriteSheetImage={`sprites/profile.png`}
              frameWidth={frameDimensions.width}
              frameHeight={frameDimensions.height}
            />
        <div className="font-semibold top-panel-gradient-text cursor-default text-xs sm:text-xl ml-2">
  ID: {sessionStorage.getItem("username")}
</div>
  
          {/* Balance and Dollar Icon Row */}
          <div className="flex items-center ml-2 sm:mt-0 sm:ml-4">
            <SpriteAnimation
              spriteSheetImage={`sprites/dollar_sign.png`}
              frameWidth={deviceType === "desktop" ? frameDimensions.width * 0.75 : frameDimensions.width * 0.3}
              frameHeight={deviceType === "desktop" ? frameDimensions.height * 0.75 : frameDimensions.height * 0.3}
              classes="mr-2"
            />
          <div className="font-semibold top-panel-gradient-text cursor-default text-xs sm:text-xl">
              {balance ? balance.toFixed(2) : "0.00"}
            </div>
          </div>
        </div>
  
        <div className="flex justify-center absolute left-1/2 transform -translate-x-1/2">
  <SpriteAnimation
    spriteSheetImage={"sprites/fiesta_logo.png"}
    frameWidth={frameDimensions.width * 1.2}
    frameHeight={frameDimensions.height * 1.2}
    totalFrames={49}
    rows={7}
    cols={7}
    fps={21}
    delay={3000}
  />
</div>
  
        <div className="flex justify-end pr-3 -mt-4">
          <SpriteAnimation
            spriteSheetImage={`sprites/menu.png`}
             frameWidth={deviceType === "desktop" ? frameDimensions.width * 0.75 : frameDimensions.width * 0.5}
            frameHeight={deviceType === "desktop" ? frameDimensions.height * 0.75 : frameDimensions.height * 0.5}
            // frameWidth={frameDimensions.width}
            //  frameHeight={frameDimensions.height}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            classes={deviceType === "desktop" ? "mr-3" : "mr-1"}
          />
          <SpriteAnimation
            spriteSheetImage={`sprites/settings.png`}
            frameWidth={deviceType === "desktop" ? frameDimensions.width * 0.75 : frameDimensions.width * 0.5}
            frameHeight={deviceType === "desktop" ? frameDimensions.height * 0.75 : frameDimensions.height * 0.5}
            // frameWidth={frameDimensions.width}
            // frameHeight={frameDimensions.height}
            onClick={() => setIsAudioSettingsOpen(!isPopoverOpen)}
          />
        </div>
      </div>
  
      <Modal
        isOpen={isAudioSettingsOpen}
        onClose={() => setIsAudioSettingsOpen(false)}
        title="Settings"
      >
        <AudioSettings />
      </Modal>
      <PopoverMenu
        ref={popoverRef}
        isOpen={isPopoverOpen}
        toggleMenu={() => setIsPopoverOpen(false)}
      />
    </div>
  );
  
  
}
