import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import favActiveIcon from "../../assets/icons/fav_active_icon.png";
import favIcon from "../../assets/icons/fav_icon.png";
import { favTablesLoaded } from "../../store/favTablesSlice";
import { Table } from "../../store/tablesModel";
import { TableDataMap } from "./constants/tablesData";
import { SpriteAnimation } from "./SpriteAnimation";

interface GameTabProps {
  table: Table;
  favTables: string[];
  onGameClick: (gameId: string) => void;
}

const GameTab: React.FC<GameTabProps> = ({
  table,
  favTables,
  onGameClick,
}) => {
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const deviceType = localStorage.getItem("deviceType");

  useEffect(() => {
    const updateDimensions = () => {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const sidebarWidth = deviceType === "desktop" ? 148 : 88;
      const availableWidth = vw - sidebarWidth - 48; // Account for sidebar and padding
      let baseWidth: number;
      
      if (vw < 640) { // mobile
        baseWidth = availableWidth ; // Account for padding
      } else if (vw < 768) { // sm
        baseWidth = (availableWidth ) / 2; // 2 columns
      } else if (vw < 1024) { // md
        baseWidth = (availableWidth ) / 2; // 2 columns with more gap
      } else if (vw < 1280) { // lg
        baseWidth = (availableWidth ) / 3; // 3 columns
      } else { // xl
        baseWidth = (availableWidth ) / 4; // 4 columns
      }

      // Maintain aspect ratio (4:3)
      const baseHeight = (baseWidth * 3) / 4;

      setDimensions({
        width: Math.floor(baseWidth),
        height: Math.floor(baseHeight)
      });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [deviceType]);

  const handleFavClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const response = await axios.post(
        "https://backend.inferixai.link/api/favorites",
        {
          tableId: table.tableId,
          isFav: !favTables.includes(table.tableId),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.data && response.data.status === "RS_OK") {
        dispatch(favTablesLoaded(response.data.favTables));
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <div className="relative w-full">
      <div className="relative w-full h-full">
        <SpriteAnimation
          spriteSheetImage={`game_thumbnail_sprites/${table.slug}.png`}
          frameWidth={dimensions.width}
          frameHeight={dimensions.height}
          onClick={() => onGameClick(table.slug)}
        />
        <button
          onClick={handleFavClick}
           className="absolute z-10"
          style={{
            top: `${dimensions.height * 0.14}px`, // 2% from the top of the frame
            right: deviceType === "desktop" ? `${dimensions.height * 0.02}px` : `${dimensions.height * 0.15}px`,
            width: `${dimensions.height * 0.12}px`, // Button width is 12% of the frame width
            height: `${dimensions.height * 0.12}px`, // Button height is 12% of the frame height
          }}
        >
          <img
            src={favTables.includes(table.tableId) ? favActiveIcon : favIcon}
            alt="favorite"
            className="w-full h-full object-contain"
          />
        </button>
      </div>
    </div>

    
  );
};

export default GameTab;

