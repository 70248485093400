import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../utils/buttons/Button";
import Modal from "../utils/modal/Modal";

interface ChangePasswordProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ isOpen, onClose }) => {
  const deviceType = localStorage.getItem("deviceType");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [spinnerText, setSpinnerText] = useState<string>("");
  const [error, setError] = useState("");

  // Field-specific error states
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const navigate = useNavigate();

  // Validation functions
  const validateCurrentPassword = () => {
    if (currentPassword.length < 8) {
      setCurrentPasswordError("Current password must be at least 8 characters");
    } else {
      setCurrentPasswordError("");
    }
  };

  const validateNewPassword = () => {
    if (newPassword.length < 8) {
      setNewPasswordError("New password must be at least 8 characters");
    } else if (newPassword === currentPassword) {
      setNewPasswordError("New password must be different from the current password");
    } else {
      setNewPasswordError("");
    }
  };

  const validateConfirmPassword = () => {
    if (confirmPassword !== newPassword) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError("");
    setSpinnerText("Updating Password...");
    setShowSpinner(true);

    // Run all validations before submitting
    validateCurrentPassword();
    validateNewPassword();
    validateConfirmPassword();

    // Check if any validation errors exist
    if (currentPasswordError || newPasswordError || confirmPasswordError) {
      setShowSpinner(false);
      setError("Please fix the errors before submitting");
      return;
    }

    try {
      const response = await axios.post(
        "https://backend.inferixai.link/api/updatepassword",
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.data) {
        setSpinnerText("Password changed successfully!");
        setTimeout(() => {
          setShowSpinner(false);
          onClose();
          navigate("/login");
        }, 1500);
      }
    } catch (err: any) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError("Failed to Change Password");
      }
      setShowSpinner(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Change Password"
      titleStyleClasses={
        deviceType === "mobile"
          ? "text-[12px] -mt-[24px] -ml-[6px]"
          : "text-[20px] -ml-2"
      }
      showSpinner={showSpinner}
      spinnerText={spinnerText}
    >
      <div
        className={`flex flex-col w-3/4 justify-center px-4 mx-auto -mt-12 ${
          deviceType === "mobile" && "pt-20 max-h-[220px] overflow-y-auto"
        }`}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mt-2 mb-2">
            <input
              className=" bg-transparent text-white"
              type="password"
              id="current-password"
              value={currentPassword}
              placeholder="Current Password"
              onChange={(e) => setCurrentPassword(e.target.value)}
              onBlur={validateCurrentPassword}
              required
            />
            {currentPasswordError && <p className="text-red-500 text-sm">{currentPasswordError}</p>}
          </div>
          <div className="flex flex-col mt-2 mb-2">
            <input
              className=" bg-transparent text-white"
              type="password"
              id="new-password"
              value={newPassword}
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              onBlur={validateNewPassword}
              required
            />
            {newPasswordError && <p className="text-red-500 text-sm">{newPasswordError}</p>}
          </div>
          <div className="flex flex-col mt-2 mb-2">
            <input
              className=" bg-transparent text-white"
              type="password"
              id="confirm-password"
              value={confirmPassword}
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={validateConfirmPassword}
              required
            />
            {confirmPasswordError && <p className="text-red-500 text-sm">{confirmPasswordError}</p>}
          </div>
          <div className="flex w-full justify-center mt-6">
            <Button type="submit" text="Save" />
          </div>
        </form>
        <div className="flex w-full p-2 -mt-2 text-red-600 text-sm font-medium justify-center">
          {error && <p>{error}</p>}
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
